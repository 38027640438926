@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



* {

  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  background-color: #EAD8B1;
}

.btn-submit{
  background-color: transparent;
  border-radius: 12px;
  border: 1px white solid;
  width: 10rem;
  height: 2rem;
  font-size: 1.2rem;
  color: #001F3F;
}
.uploader{
  background-color: transparent;
  /* border-radius: 12px; */
  /* border: 1px white solid; */
  width: 10rem;
  height: 2rem;
  font-size: 1.2rem;
  color: #001F3F;
  margin: 2vw;
}
.predictions{
  font-size: 1.2rem;
  color: #001F3F;
  margin: 2vw;
  
}
.img-uploaded{
  width: 40vw;
  
}
